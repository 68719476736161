import { BlogFulfillmentList } from './BlogFulfillmentList';
import { useBlogTransportsQueryApi } from 'graphql/generated';

export const BlogTransportsList: FC = () => {
    const [{ data }] = useBlogTransportsQueryApi();

    return (
        <BlogFulfillmentList
            fulfillmentItems={data?.transportsList}
            freeFulfillmentLimitWithVat={data?.freeFulfillmentLimitWithVat}
        />
    );
};
