import { BlogFulfillmentList } from './BlogFulfillmentList';
import { useBlogPaymentsQueryApi } from 'graphql/generated';

export const BlogPaymentsList: FC = () => {
    const [{ data }] = useBlogPaymentsQueryApi();

    return (
        <BlogFulfillmentList
            fulfillmentItems={data?.payments}
            freeFulfillmentLimitWithVat={data?.freeFulfillmentLimitWithVat}
        />
    );
};
