export const parseCatnums = (text: string[]): string[] => {
    const productsRegex = new RegExp(/\[gjc-comp-ProductList&#61;([\d,.]+)]/g);
    const productsCatnum: string[] = [];

    text.forEach((textPart) => {
        const products = productsRegex.exec(textPart);
        if (products !== null) {
            productsCatnum.push(...products[1].split(','));
        }
    });

    return productsCatnum;
};
