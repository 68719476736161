import styled, { css } from 'styled-components';

export const UnwatchWrapperStyled = styled.span`
    ${({ theme }) => css`
        margin-top: 13px;
        display: block;

        color: ${theme.color.black};

        @media ${theme.mediaQueries.queryLg} {
            margin: 13px 20px 16px 20px;
        }

        @media ${theme.mediaQueries.queryVl} {
            margin: 13px 24px 16px 24px;
        }
    `}
`;

export const UnwatchLinkStyled = styled.span`
    ${({ theme }) => css`
        cursor: pointer;
        border-bottom: 1px solid ${theme.color.border};
    `}
`;
