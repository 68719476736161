import ProductsList, { ProductListTypes } from 'components/Blocks/Product/List/ProductsList';
import UserText from 'components/Helpers/UserText/UserText';
import { BlogPaymentsList } from 'components/Pages/BlogArticle/BlogFulfillmentList/BlogPaymentList';
import { BlogTransportsList } from 'components/Pages/BlogArticle/BlogFulfillmentList/BlogTransportsList';
import { useProductsByCatnums } from 'connectors/products/Products';
import { replaceAll } from 'helpers/replaceAll';
import { useGtmProductListView } from 'hooks/gtm/useGtmProductListView';
import { memo, useRef } from 'react';
import { GtmListNameType } from 'types/gtm';
import { ListedProductType } from 'types/product';
import { parseCatnums } from 'utils/grapesJsParser';

type GrapeJsParserProps = {
    text: string[];
    gtmListName: GtmListNameType;
    uuid: string;
};

const GrapeJsParser: FC<GrapeJsParserProps> = ({ text, gtmListName, uuid }) => {
    const productListInstancesCountRef = useRef(0);
    const productsCatnum = parseCatnums(text);
    const allProducts = useProductsByCatnums(productsCatnum);

    const RenderArticleProductList = (part: string, index: number) => {
        const instanceNumber = useGtmProductListView(allProducts, gtmListName, productListInstancesCountRef);

        if (allProducts === null) {
            return (
                <ProductsList
                    products={[]}
                    gtmListName={`${gtmListName} ${instanceNumber}`}
                    type={ProductListTypes.Normal}
                    key={`${uuid}productList${index}`}
                    inArticle
                    isLoading
                    underline={false}
                />
            );
        }

        const products = replaceAll(part, /\[gjc-comp-ProductList&#61;|\]/g, '')
            .split(',')
            .map((product) => allProducts.find((blogArticleProduct) => blogArticleProduct.catalogNumber === product))
            .filter(Boolean) as ListedProductType[];

        return (
            <ProductsList
                products={products}
                gtmListName={`${gtmListName} ${instanceNumber}`}
                type={ProductListTypes.Normal}
                key={`${uuid}productList${index}`}
                inArticle
                underline={false}
            />
        );
    };

    const renderGrapesJsParts = (part: string, index: number) => {
        if (part.match(/\[gjc-comp-FulfillmentList&#61;payment\]/g)) {
            return <BlogPaymentsList />;
        }

        if (part.match(/\[gjc-comp-FulfillmentList&#61;shipping\]/g)) {
            return <BlogTransportsList />;
        }

        if (part.match(/\[gjc-comp-(.*?)\]/g)) {
            return RenderArticleProductList(part, index);
        }

        return <UserText key={`${uuid}textPart${index}`} htmlContent={part} isGrapesJs />;
    };

    return <>{text.map((part, index) => renderGrapesJsParts(part, index))}</>;
};

export default memo(GrapeJsParser);
