import ProductItem from './ListedItem/ProductItem';
import { ProductItemSkeleton } from './ListedItem/ProductItemSkeleton';
import { DEFAULT_PAGE_SIZE } from 'config/constants';
import { createEmptyArray } from 'helpers/arrayUtils';
import { useEffect } from 'react';
import { useSessionStore } from 'store/useSessionStore';
import { twJoin } from 'tailwind-merge';
import { GtmListNameType } from 'types/gtm';
import { ListedProductType } from 'types/product';

export enum ProductListTypes {
    Normal,
    WatchDog,
}

type ProductsListProps = {
    currentPage?: number;
    gtmListName: GtmListNameType;
    isLoading?: boolean;
    onUnwatch?: (productUuid: string) => void;
    products: ListedProductType[] | undefined | null;
    type: ProductListTypes;
    inArticle?: boolean;
    isLoadMore?: boolean;
    underline?: boolean;
    shouldRedirectOnClick?: boolean;
    onProductItemClick?: (product: ListedProductType) => void;
    isConfigurator?: boolean;
};

function productBottomEqualHeight() {
    const productList = document.querySelector<HTMLElement>('[data-id="productList"]');
    const items = document.querySelectorAll<HTMLElement>('[data-id="productBottom"]');

    if (productList === null) {
        return;
    }

    const columnCount = getComputedStyle(productList).gridTemplateColumns.split(' ').length;
    const maxHeightOnEachLine = [];

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item instanceof HTMLElement) {
            item.style.height = '';

            const currentColumn = Math.floor(i / columnCount);
            const height = item.clientHeight;

            if (!maxHeightOnEachLine[currentColumn] || height > maxHeightOnEachLine[currentColumn]) {
                maxHeightOnEachLine[currentColumn] = height;
            }
        }
    }

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item instanceof HTMLElement) {
            const currentColumn = Math.floor(i / columnCount);
            item.style.height = maxHeightOnEachLine[currentColumn] + 'px';
        }
    }
}

const TEST_IDENTIFIER = 'blocks-product-list';

const ProductsList: FC<ProductsListProps> = ({
    currentPage = 1,
    gtmListName,
    isLoading,
    onUnwatch,
    products,
    type,
    inArticle = false,
    isLoadMore,
    underline = true,
    onProductItemClick,
    shouldRedirectOnClick = true,
    isConfigurator,
}) => {
    const { isPageLoading } = useSessionStore((s) => s);
    useEffect(() => {
        let timer: number | undefined;
        const handler = () => {
            window.clearTimeout(timer);
            timer = window.setTimeout(productBottomEqualHeight, 200);
        };

        window.addEventListener('resize', handler, { passive: true });
        productBottomEqualHeight();

        return () => {
            window.removeEventListener('resize', handler);
            window.clearTimeout(timer);
        };
    }, []);

    return (
        <div
            className={twJoin(
                'relative my-12 grid grid-cols-2 gap-y-11 gap-x-3 lg:grid-cols-3 lg:gap-y-4 xl:grid-cols-4',
                underline &&
                    'after:pointer-events-none after:absolute after:-left-1 after:-right-1 after:-bottom-5 after:h-[1px] after:bg-grayLighter after:content-[""] lg:after:-bottom-2',
            )}
            data-testid={TEST_IDENTIFIER}
            data-id="productList"
        >
            {(!isLoading || isLoadMore) &&
                !isPageLoading &&
                products?.map((listedProductItem, index) => (
                    <ProductItem
                        gtmListName={gtmListName}
                        key={listedProductItem.uuid}
                        listIndex={(currentPage - 1) * DEFAULT_PAGE_SIZE + index}
                        onUnwatch={() => onUnwatch?.(listedProductItem.uuid)}
                        product={listedProductItem}
                        type={type}
                        inArticle={inArticle}
                        underline={underline}
                        onProductItemClick={onProductItemClick}
                        shouldRedirectOnClick={shouldRedirectOnClick}
                        isConfigurator={isConfigurator}
                    />
                ))}
            {isLoading && createEmptyArray(10).map((_, index) => <ProductItemSkeleton key={index} />)}
        </div>
    );
};

export default ProductsList;
