import { BlogPaymentsQueryApi, BlogTransportsQueryApi } from 'graphql/generated';
import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import useTranslation from 'next-translate/useTranslation';
import { twJoin } from 'tailwind-merge';

type BlogFulfillmentListProps = {
    freeFulfillmentLimitWithVat: string | null | undefined;
    fulfillmentItems: BlogPaymentsQueryApi['payments'] | BlogTransportsQueryApi['transportsList'] | undefined;
};

export const BlogFulfillmentList: FC<BlogFulfillmentListProps> = ({
    freeFulfillmentLimitWithVat,
    fulfillmentItems,
}) => {
    const formatPrice = useFormatPrice();
    const { t } = useTranslation();

    return (
        <ul className="gjs-fulfillment-list">
            {freeFulfillmentLimitWithVat && (
                <li className="text-green" key={Math.random()}>
                    <span>
                        {t('Order above {{ price }}', {
                            price: formatPrice(parseFloat(freeFulfillmentLimitWithVat)),
                        })}
                    </span>
                    <span className="lowercase">{t('Free')}</span>
                </li>
            )}
            {fulfillmentItems?.map((fulfillment) => (
                <li
                    key={fulfillment.uuid}
                    className={twJoin(fulfillment.price.priceWithVat[0] === '0' && 'text-green')}
                >
                    <span>{fulfillment.name}</span>
                    <span
                        className={twJoin(
                            formatPrice(parseFloat(fulfillment.price.priceWithVat)) === t('Free') && 'lowercase',
                        )}
                    >
                        {formatPrice(parseFloat(fulfillment.price.priceWithVat))}
                    </span>
                </li>
            ))}
        </ul>
    );
};
