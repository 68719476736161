import { UnwatchLinkStyled, UnwatchWrapperStyled } from './Unwatch.style';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';

type UnwatchProps = {
    onUnwatch: () => void;
};

const Unwatch: FC<UnwatchProps> = ({ onUnwatch }) => {
    const t = useTypedTranslationFunction();

    return (
        <UnwatchWrapperStyled>
            <UnwatchLinkStyled onClick={onUnwatch}>{t('Cancel watch')}</UnwatchLinkStyled>
        </UnwatchWrapperStyled>
    );
};

export default Unwatch;
